import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  constructor(private readonly _baseHttpService: BaseHttpService) {}

  public updatePassword(data: any): Promise<boolean> {
    return lastValueFrom(this._baseHttpService.post('usuario/change-password', data));
  }
}
