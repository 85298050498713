import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PasswordValidator } from 'src/app/shared/validators/password-validator';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  public changePasswordForm!: FormGroup;
  public showForm = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public changePasswordService: ChangePasswordService,
    private fb: FormBuilder,
    private toasterService: ToasterService
  ) { }
  get form(): any {
    return this.changePasswordForm.controls;
  }
  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required, PasswordValidator.passwordValidator.bind(this)]],
      newPassword: ['', [Validators.required, PasswordValidator.passwordValidator.bind(this)]],
      repeatPassword: ['', [Validators.required, PasswordValidator.passwordValidator.bind(this)]],
    });
    this.showForm = true;
  }

  public save(): void {
    if (this.changePasswordForm.valid) {
      const postData = this.changePasswordForm.value;
      let tmpResponse!: boolean;
      if (postData.newPassword === postData.repeatPassword) {
        delete postData.repeatPassword;
        this.changePasswordService.updatePassword(postData)
          .then((resp) => {
            if (resp) {
              tmpResponse = true;
            }
          })
          .catch((err) => {
            this.toasterService.error(err.error.message);
          })
          .finally(() => {
            this.closeModal(tmpResponse);
          });
      } else {
        this.toasterService.error('El campo Nueva contraseña y repetir contraseña deben ser el mismo')
      }
    } else {
      this.toasterService.error('Datos Incorrectos');
    }
  }


  public closeModal(data: any = undefined): void {
    this.ref.close(data);
  }

}
