import { Injectable } from '@angular/core';
import { isArray } from 'lodash-es';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private messageService: MessageService) {}

  public success(message: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Correcto',
      detail: message,
    });
  }
  public error(message: string|string[]): void {
    if (isArray(message)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message.join(','),
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: message,
      });
    }
  }
  public info(message: string): void {
    this.messageService.add({
      severity: 'info',
      summary: 'Informacion',
      detail: message,
    });
  }
  public warn(message: string): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: message,
    });
  }
}
