import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/app.layout.service';

@Component({
  selector: 'app-footer-template',
  templateUrl: './footer-template.component.html',
  styleUrls: ['./footer-template.component.scss']
})
export class FooterTemplateComponent implements OnInit {

  constructor(public layoutService: LayoutService) { }

  ngOnInit(): void {
  }

}
