import { Component } from '@angular/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { CredentialsService } from './shared/services';
import { GenericFunctions } from './shared/services/generic-functions';
import { coreFunctions } from './shared/utils/utils';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  private translationsCalendar: Translation = this._genericFunctions.dateEsConfig();
  constructor(private primengConfig: PrimeNGConfig, private readonly _genericFunctions: GenericFunctions, public a: CredentialsService) {}

  ngOnInit() {
    this.primengConfig.ripple = false;
    this.primengConfig.setTranslation(this.translationsCalendar);
    coreFunctions.main();
  }
}
