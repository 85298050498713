import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import compact from 'lodash-es/compact';
import {HttpOptions} from "../types";
import { coreFunctions } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  public urlBase: string = environment.host;
  constructor(private httpClient: HttpClient) {
    coreFunctions.main();
  }

  /**
   * POST SERVICE
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public post<T>(namespace: string, payload: T, options?: HttpOptions): Observable<any> {
    const url = encodeURI(compact([ this.urlBase, namespace ]).join('/'));
    return this.httpClient.post(url, payload, options);
  }

  /**
   * GET SERVICE
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public get(namespace: string, options?: HttpOptions): Observable<any> {
    const url = encodeURI(compact([ this.urlBase, namespace ]).join('/'));
    return this.httpClient.get(url, options);
  }

  /**
   * PUT SERVICE
   *
   * @private
   * @template T
   * @param {string} namespace
   * @param {T} payload
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public put<T>(namespace: string, payload: T): Observable<any> {
    const url = encodeURI(compact([ this.urlBase, namespace ]).join('/'));
    return this.httpClient.put(url, payload);
  }

  /**
   * DELETE SERVICE
   *
   * @private
   * @param {string} namespace
   * @returns {Observable<any>}
   * @memberof BaseHttpService
   */
  public delete(namespace: string): Observable<any> {
    const url = encodeURI(compact([ this.urlBase, namespace ]).join('/'));
    return this.httpClient.delete(url);
  }
}
